import React, {useContext, useState, useEffect} from 'react';

import {isSeoRender} from '@/modules/SEO';
import {defaultKameleoon, isKameleoonAvailable, KameleoonService, retrieveKameleoon} from './KameleoonAPI';

const KAMELEOON_READY_EVENT = 'Kameleoon::Started';

const KameleoonContext = React.createContext<KameleoonService>({
  getVariantForName: () => '',
});

function initializeKameleoon({setKameleoon}: {setKameleoon: (k: KameleoonService) => void}) {
  if (isKameleoonAvailable()) {
    setKameleoon(retrieveKameleoon());
  } else {
    window.addEventListener(KAMELEOON_READY_EVENT, () => {
      setKameleoon(retrieveKameleoon());
    });
  }
}

const KameleoonProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [k, setKameleoon] = useState<KameleoonService>(defaultKameleoon);

  useEffect(() => {
    if (!isSeoRender) {
      initializeKameleoon({setKameleoon});
    }
  }, []);

  return <KameleoonContext.Provider value={k}>{children}</KameleoonContext.Provider>;
};

const useKameleoon = () => useContext(KameleoonContext);

export {useKameleoon, KameleoonProvider};
