import React, {useContext, useMemo} from 'react';

import {useFreeTrial} from '@/pages/Home/hooks/useFreeTrial';
import {useTalents} from '@/pages/Home/hooks/useTalents';

interface MendelContextValue {
  freeTrial: {
    isLoading: boolean;
    isExpEnabled: boolean;
    hasFreeTrial: boolean;
    text?: {
      cta: string;
      aboutCost: string;
      howItWorks: string;
    };
  };
  talents: {
    isLoading: boolean;
    isExpEnabled: boolean;
    imagesBasePath: string;
    video: {
      desktop: string;
      mobile: string;
    };
  };
}

const initialValue: MendelContextValue = {
  freeTrial: {
    isLoading: false,
    isExpEnabled: false,
    hasFreeTrial: false,
  },
  talents: {
    isLoading: false,
    isExpEnabled: false,
    imagesBasePath: '',
    video: {
      desktop: '',
      mobile: '',
    },
  },
};

const MendelContext = React.createContext<MendelContextValue>(initialValue);

const MendelProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const freeTrialResult = useFreeTrial();
  const talentsResult = useTalents();

  const value = useMemo(() => ({freeTrial: freeTrialResult, talents: talentsResult}), [freeTrialResult, talentsResult]);

  return <MendelContext.Provider value={value}>{children}</MendelContext.Provider>;
};

const useMendelContext = () => {
  const context = useContext(MendelContext);

  if (context === undefined) {
    throw new Error('useMendelContext must be used within MendelProvider');
  }

  return context;
};

export {useMendelContext, MendelProvider};
