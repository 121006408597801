import {SessionStorage} from '@/utils/sessionStorage';

const PROMO_URL_PARAM = 'promo';
const PROMO_KEY_STORAGE = 'LKPROMO';

const getSearchParam = (parameter = '') => {
  const params = new URLSearchParams(window.location.search);
  return params.get(parameter) || '';
};

export const setupUrlPromo = (): void => {
  const urlPromo = getSearchParam(PROMO_URL_PARAM);
  if (urlPromo) SessionStorage.set(PROMO_KEY_STORAGE, urlPromo);
};

export const getUrlPromo = (): string | null => {
  return SessionStorage.get(PROMO_KEY_STORAGE);
};
