import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {UI_EVENT, eventManager} from '@/modules/EventManager';
import {scrollToTop} from '@/utils/scrollToTop';

export const RouterEvents: React.FC = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    let section = pathname.split('/')[1] || '';
    if (section === '') section = 'home';
    eventManager.emit(UI_EVENT.PAGE_VIEW, {section});
    scrollToTop();
  }, [pathname]);

  return null;
};
