import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  container: {
    gap: '$space5',
  },
  link: {
    display: 'flex',
  },
  linkText: {
    paddingBottom: 2,
  },
});
