import {useCallback, useEffect, useRef} from 'react';

const safeDocument: Document = document;

type ScrollLock = {
  preventScroll: () => void;
  allowScroll: () => void;
};

export const useScrollLock = (lockScroll: boolean): ScrollLock => {
  const html = safeDocument.documentElement;
  const {body} = safeDocument;
  const scrollBlocked = useRef(body?.style?.overflow === 'hidden');

  const preventScroll = useCallback((): void => {
    if (!body || !body.style || scrollBlocked.current) return;
    if (document == undefined) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight = Number(window.getComputedStyle(body).getPropertyValue('padding-right'));

    /**
     * Based on this solution https://gist.github.com/celian-rib/fcfb6ed3a96f86b4e7c3ea59df5c16a6
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */

    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  }, [body, html.clientWidth]);

  const allowScroll = useCallback((): void => {
    if (!body || !body.style || !scrollBlocked.current) return;

    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;
  }, [body]);

  useEffect(() => {
    lockScroll ? preventScroll() : allowScroll();
  }, [preventScroll, lockScroll, allowScroll]);

  return {preventScroll, allowScroll};
};
