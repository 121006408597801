import StyleSheet from 'react-native-extended-stylesheet';
import {HEADER_HEIGHT} from '@/components/organisms/Header/Header';

export const styles = StyleSheet.create({
  container: {
    zIndex: '$elevationLayerM',
    position: 'fixed',
  },
  header: {
    zIndex: '$elevationLayerS',
  },
  login: {
    marginRight: '$space6',
  },
  logo: {
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
