import React from 'react';

import {KameleoonProvider} from '../kameleoon/context/KameleoonContext';
import {MendelProvider} from '../mendel/context/MendelContext';

export const ABTestingProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <KameleoonProvider>
      <MendelProvider>{children}</MendelProvider>
    </KameleoonProvider>
  );
};
