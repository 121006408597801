import {useMemo} from 'react';

import {useMultiExperiments} from './useMultiExperiments';

export type Variant = string | undefined;
export type UseExperimentResult = {isLoading: boolean; variant: Variant};
export type UseExperimentType = (args: {
  name: string;
  forcedVariant?: string;
  enabled?: boolean;
  isWaiting?: boolean;
}) => UseExperimentResult;

/**
 * Resolves the experiment provided
 * @param name name of the experiment to evaluate
 * @param forcedVariant name of the variant to force assignation
 * @param enabled true if we want to assign the experiment
 * @param isWaiting true if we want to wait before evaluating the enabled property
 */
const useExperiment: UseExperimentType = ({name, forcedVariant, enabled = true, isWaiting = false}) => {
  const exp = useMemo(() => [{name, forcedVariant, enabled}], [name, forcedVariant, enabled]);
  const {isLoading, variants} = useMultiExperiments({experiments: exp, isWaiting});
  return {isLoading, variant: variants[name]};
};

export {useExperiment};
