import React from 'react';
import {Button, BUTTON_VARIANT} from '@lookiero/aurora';

import {useIntl} from '@/modules/I18N';
import {PATH} from '@/routes/Router.config';

export const LoginButton: React.FC = () => {
  const {formatMessage} = useIntl();

  const handleOnLogin = () => {
    window.location.href = PATH.LOGIN;
  };

  return (
    <Button variant={BUTTON_VARIANT.SECONDARY} onPress={handleOnLogin} nativeID="HomeLoginBurgerMenu" small>
      {formatMessage({id: 'navigation.menu.login', defaultMessage: 'Log in'})}
    </Button>
  );
};
