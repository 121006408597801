import React from 'react';

import {ErrorHandler, ErrorHandlerProvider} from '@lookiero/user-errorhandler-front';

import {AppProvider} from '@/components/contexts';
import {AppRouter} from '@/routes';
import {ABTestingProvider} from '@/modules/ABTesting';
import {setupTracking} from '@/modules/Tracking';

import '@/utils/polyfills';
import './App.css';

setupTracking();

export const App = () => (
  <ErrorHandlerProvider
    dsn={process.env.REACT_APP_SENTRY_DSN}
    environment={process.env.REACT_APP_ENVIRONMENT}
    release={process.env.REACT_APP_RELEASE || 'no-release'}
  >
    <ErrorHandler section="usf-app">
      <ABTestingProvider>
        <AppProvider>
          <AppRouter />
        </AppProvider>
      </ABTestingProvider>
    </ErrorHandler>
  </ErrorHandlerProvider>
);
