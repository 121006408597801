import {useMemo} from 'react';
import {getSiteConfig} from '@/modules/Site';
import {Site} from '@/types/Site';
import {LINKS, LinkProps} from '../config/Links';

const SITES_WITHOUT_BLOG = [Site.SE, Site.CH];
const SITES_WITH_SUSTAINABILITY = [Site.ES];

export const usePanelLinks = (): LinkProps[] => {
  const {site} = getSiteConfig();

  const isBlogAvailable = !SITES_WITHOUT_BLOG.includes(site);
  const isSustainabilityAvailable = SITES_WITH_SUSTAINABILITY.includes(site);

  return useMemo(
    () => [
      ...(isBlogAvailable ? [LINKS.BLOG] : []),
      LINKS.GIFTCARD,
      LINKS.FAQ,
      LINKS.LEGAL,
      ...(isSustainabilityAvailable ? [LINKS.SUSTAINABILITY] : []),
    ],
    [isBlogAvailable, isSustainabilityAvailable],
  );
};
