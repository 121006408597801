import React from 'react';
import {ALIGN, Header as AuroraHeader, Link, Motion, useDevice, View} from '@lookiero/aurora';

import {PATH} from '@/routes/Router.config';
import {useIntl} from '@/modules/I18N';
import {styles} from './Header.styles';
import {Logo} from '@/components/primitives/Logo';
import {useHeadless} from './hooks/useHeadless';

export const HEADER_HEIGHT = 48;

interface HeaderProps {
  isVisible: boolean;
  onLogoPress: () => void;
  openPanel?: () => void;
  showLoginLink: boolean;
}

export const Header: React.FC<HeaderProps> = ({isVisible, onLogoPress, openPanel, showLoginLink}) => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();
  const {headless} = useHeadless();

  return (
    !headless && (
      <Motion
        style={styles.container}
        value={{
          opacity: isVisible ? 1 : 0,
          top: isVisible ? '0px' : `${0 - HEADER_HEIGHT}px`,
        }}
        wide
      >
        <AuroraHeader
          style={styles.header}
          logo={
            <View style={styles.logo}>
              <Link action level={3} onPress={onLogoPress}>
                <Logo />
              </Link>
            </View>
          }
          onMenu={openPanel ? openPanel : undefined}
        >
          {screen.L && showLoginLink && (
            <Link action align={ALIGN.CENTER} level={3} style={styles.login} href={PATH.LOGIN}>
              {formatMessage({id: 'home.login'})}
            </Link>
          )}
        </AuroraHeader>
      </Motion>
    )
  );
};
