import React, {Suspense} from 'react';

export const withSuspense = <T extends object>(Component: React.ComponentType<T>) => {
  return (props: T) => {
    return (
      <Suspense fallback={<div></div>}>
        <Component {...props} />
      </Suspense>
    );
  };
};
