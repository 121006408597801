import {useMemo, useEffect} from 'react';
import {useMendel} from '@/modules/ABTesting';
import {experiments} from '@/modules/ABTesting/mendel/config/Experiments.definition';
import {getSiteConfig} from '@/modules/Site';
import {Site} from '@/types/Site';

const STORAGE_KEY = 'grw_freeTrialPromo';
const PROMO_VALUE = 'ILMIOSTILE';

const LOCALISE = {
  cta: 'exp_home.main.cta',
  aboutCost: 'exp_home.additionalinfo2.quote2',
  howItWorks: 'exp_home.how.step1_paragraph',
};

const {GRW_FREE_TRIAL_OUT_AFTERMATH} = experiments;

interface Result {
  isLoading: boolean;
  isEnabled: boolean;
  text?: {
    cta: string;
    aboutCost: string;
    howItWorks: string;
  };
}

export const useFreeTrialOutAftermath = ({enabled = true}): Result => {
  const {site} = getSiteConfig();

  const {isLoading, variant} = useMendel({
    name: GRW_FREE_TRIAL_OUT_AFTERMATH.name,
    enabled: enabled && site === Site.IT,
  });

  const isEnabled = variant === GRW_FREE_TRIAL_OUT_AFTERMATH.variants.v1;
  const text = isEnabled ? LOCALISE : undefined;

  useEffect(() => {
    if (isEnabled) {
      localStorage.setItem(STORAGE_KEY, PROMO_VALUE);
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }
  }, [isEnabled]);

  return useMemo(() => ({isLoading, isEnabled, text}), [isLoading, isEnabled, text]);
};
