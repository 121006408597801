import {useLocation} from 'react-router-dom';

export const useHeadless = (): {headless: boolean} => {
  const {search} = useLocation();
  const params = new URLSearchParams(search);

  return {
    headless: Boolean(params.get('headless')),
  };
};
