import {Country} from '@/types/Country';
import {Site} from '@/types/Site';

export interface SiteConfig {
  country: Country;
  site: Site;
  domain: string;
  locale: string;
}

export const SITE_CONFIG: Record<Site, SiteConfig> = {
  [Site.UK]: {
    country: Country.GB,
    site: Site.UK,
    domain: 'co.uk',
    locale: 'en-GB',
  },
  [Site.FR]: {
    country: Country.FR,
    site: Site.FR,
    domain: 'fr',
    locale: 'fr-FR',
  },
  [Site.ES]: {
    country: Country.ES,
    site: Site.ES,
    domain: 'es',
    locale: 'es-ES',
  },
  [Site.PT]: {
    country: Country.PT,
    site: Site.PT,
    domain: 'pt',
    locale: 'pt-PT',
  },
  [Site.BE]: {
    country: Country.BE,
    site: Site.BE,
    domain: 'be',
    locale: 'fr-BE',
  },
  [Site.IT]: {
    country: Country.IT,
    site: Site.IT,
    domain: 'it',
    locale: 'it-IT',
  },
  [Site.DE]: {
    country: Country.DE,
    site: Site.DE,
    domain: 'de',
    locale: 'de-DE',
  },
  [Site.AT]: {
    country: Country.AT,
    site: Site.AT,
    domain: 'at',
    locale: 'de-AT',
  },
  [Site.NL]: {
    country: Country.NL,
    site: Site.NL,
    domain: 'nl',
    locale: 'nl-NL',
  },
  [Site.SE]: {
    country: Country.SE,
    site: Site.SE,
    domain: 'se',
    locale: 'sv-SE',
  },
  [Site.CH]: {
    country: Country.CH,
    site: Site.CH,
    domain: 'ch',
    locale: 'de-CH',
  },
};
