import {lazy} from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toDefault = (key: string) => (namedExports: {[index: string]: any}) => {
  const {[key]: component} = namedExports;
  return {default: component};
};

export const Home = lazy(() => import('@/pages/Home').then(toDefault('Home')));
export const Legal = lazy(() => import('@/pages/Legal').then(toDefault('Legal')));
export const Unsubscribe = lazy(() => import('@/pages/Unsubscribe').then(toDefault('Unsubscribe')));
export const HomeSiteCom = lazy(() => import('@/pages/HomeSiteCom').then(toDefault('HomeSiteCom')));
export const NotFound = lazy(() => import('@/pages/NotFound').then(toDefault('NotFound')));
export const Sustainability = lazy(() => import('@/pages/Sustainability').then(toDefault('Sustainability')));
export const ReferralsLanding = lazy(() => import('@/pages/Referrals').then(toDefault('ReferralsLanding')));
export const Faq = lazy(() => import('@/pages/Faq').then(toDefault('Faq')));
export const GiftCardLanding = lazy(() =>
  import('@/pages/GiftCard/GiftCardLanding').then(toDefault('GiftCardLanding')),
);
export const GiftCardDigital = lazy(() =>
  import('@/pages/GiftCard/GiftCardDigital').then(toDefault('GiftCardDigital')),
);
export const GiftCardPrint = lazy(() => import('@/pages/GiftCard/GiftCardPrint').then(toDefault('GiftCardPrint')));
export const GiftCardThankYou = lazy(() => import('@/pages/GiftCard/ThankYou').then(toDefault('ThankYou')));
