import {useMemo} from 'react';
import {useMendel} from '@/modules/ABTesting';
import {experiments} from '@/modules/ABTesting/mendel/config/Experiments.definition';

const {GRW_JUN_24_TALENTS} = experiments;

const TALENT_LAIA_IMAGES_BASE_PATH = `${process.env.REACT_APP_ASSETS_PATH}/images/talents/laia`;
const TALENT_LAIA_VIDEOS_BASE_PATH = `${process.env.REACT_APP_ASSETS_PATH}/videos/talents/laia`;
const VIDEO_DESKTOP_CONTROL = `${process.env.REACT_APP_ASSETS_PATH}/videos/intro/rebrand/intro_desktop.mp4`;
const VIDEO_MOBILE_CONTROL = `${process.env.REACT_APP_ASSETS_PATH}/videos/intro/rebrand/intro_mobile.mp4`;
const VIDEO_DESKTOP_VARIANT1 = `${TALENT_LAIA_VIDEOS_BASE_PATH}/V1/desktop.mp4`;
const VIDEO_MOBILE_VARIANT1 = `${TALENT_LAIA_VIDEOS_BASE_PATH}/V1/mobile.mp4`;
const VIDEO_DESKTOP_VARIANT2 = `${TALENT_LAIA_VIDEOS_BASE_PATH}/V2/desktop.mp4`;
const VIDEO_MOBILE_VARIANT2 = `${TALENT_LAIA_VIDEOS_BASE_PATH}/V2/mobile.mp4`;

interface Result {
  isLoading: boolean;
  isExpEnabled: boolean;
  imagesBasePath: string;
  video: {
    desktop: string;
    mobile: string;
  };
}

export const useTalents = (): Result => {
  const {isLoading, variant} = useMendel({
    name: GRW_JUN_24_TALENTS.name,
  });

  const {isExpEnabled, imagesBasePath, video} = useMemo(() => {
    const isExpEnabled = variant === GRW_JUN_24_TALENTS.variants.v1 || variant === GRW_JUN_24_TALENTS.variants.v2;

    const imagesBasePath = isExpEnabled ? TALENT_LAIA_IMAGES_BASE_PATH : '';

    const video = (() => {
      switch (variant) {
        case GRW_JUN_24_TALENTS.variants.v1:
          return {desktop: VIDEO_DESKTOP_VARIANT1, mobile: VIDEO_MOBILE_VARIANT1};
        case GRW_JUN_24_TALENTS.variants.v2:
          return {desktop: VIDEO_DESKTOP_VARIANT2, mobile: VIDEO_MOBILE_VARIANT2};
        default:
          return {desktop: VIDEO_DESKTOP_CONTROL, mobile: VIDEO_MOBILE_CONTROL};
      }
    })();

    return {isExpEnabled, imagesBasePath, video};
  }, [variant]);

  return useMemo(() => ({isLoading, isExpEnabled, imagesBasePath, video}), [
    isLoading,
    isExpEnabled,
    imagesBasePath,
    video,
  ]);
};
