const request = <T>(url: string, options?: RequestInit): Promise<T> =>
  new Promise((resolve, reject) => {
    fetch(url, options)
      .then(async response => {
        if (response.status >= 400) {
          response.text().then(function (text) {
            reject(new Error(`Response Error ${response.status}: message=[${text}] url=[${url}]`));
          });
        } else {
          const json = await response.json().catch(() => ({}));
          resolve(json);
        }
      })
      .catch(({message = 'Something wrong happened. Try again.', response} = {}) => {
        reject(new Error(`HTTP Error ${response ? response.status : 'unknown'}: message=[${message}] url=[${url}]`));
      });
  });

export const http = {
  get: <T>(url: string) => request<T>(url),
  post: (url: string, data: Record<string, unknown> | string) =>
    request(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json;charset=UTF-8', Accept: 'application/json'},
      body: typeof data === 'string' ? data : JSON.stringify(data),
    }),
};
