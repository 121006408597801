import React, {useEffect, useState, useCallback} from 'react';
import {OnErrorFn, IntlErrorCode} from '@formatjs/intl';
import {IntlProvider} from 'react-intl';

import {getSiteConfig} from '@/modules/Site';
import {LocaleDictionary} from '@/modules/I18N/types/Locale';
import {sendLogMessage} from '@/modules/Monitoring';
import {isSeoRender} from '@/modules/SEO';
import {Country} from '@/types/Country';

// TODO Albareto esto habría que borrarlo, es solo para tener datos
let executions = 0;

export const LocalI18NProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {country, locale} = getSiteConfig();
  const [messages, setMessages] = useState<LocaleDictionary>(window.Lookiero.i18n || {});

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.Lookiero.i18n) {
        clearInterval(interval);
        setMessages(window.Lookiero.i18n);
        if (isSeoRender) return;
        const subtitle = window.Lookiero.i18n['home.additionalinfo2.subtitle'];
        if (
          (country === Country.ES && subtitle !== '¿Cuánto cuesta tu Lookiero?') ||
          (country === Country.FR && subtitle !== `Combien coûte <br>une box Lookiero ?`)
        ) {
          void sendLogMessage(
            `Translation encoding error country=[${country}] userAgent=[${navigator.userAgent}] translation=${window.Lookiero.i18n['home.additionalinfo2.subtitle']}`,
          );
        }
        const el = document.querySelector('html') as HTMLHtmlElement;
        el.classList.add('loaded');
        if (executions > 0) {
          void sendLogMessage(`USF i18nContext loaded before translations were ready executions=${executions}`);
        }
      } else {
        executions++;
      }
    }, 10);

    return () => clearInterval(interval);
  }, [country]);

  const onError: OnErrorFn = useCallback(err => {
    if (err.code === IntlErrorCode.MISSING_TRANSLATION) return;
    throw err;
  }, []);

  return (
    <IntlProvider onError={onError} locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};
