import React, {useMemo} from 'react';

import {ASSETS_COMMONS_URL, IMAGES_URL} from '@/config';
import {useIntl} from '@/modules/I18N';
import {isHomeRoute} from '@/routes';
import {SEO} from '@/modules/SEO';
import {getSiteConfig, getSites, getLookieroURL} from '@/modules/Site';
import {determineContent} from './services';

const PATH_TO_FAVICONS = `${ASSETS_COMMONS_URL}/favicon/v2`;
const PATH_TO_SHARE_IMAGE = `${IMAGES_URL}/share/v2/lookiero.jpg`;

interface SEOManagerProps {
  path?: string;
}

export const SEOManager: React.FC<SEOManagerProps> = ({path = ''}) => {
  const {formatMessage, loadedMessages} = useIntl();
  const {domain, locale} = getSiteConfig();

  const content = useMemo(() => determineContent(path), [path]);

  const titlePage = useMemo(() => {
    if (!loadedMessages) return '';
    const organization = `${formatMessage({id: 'head.organization'})} | `;
    const titlePage = formatMessage({id: `head.title.${content}`});
    return organization + titlePage;
  }, [content, loadedMessages, formatMessage]);

  const metaUrls = useMemo(() => getSites().map(el => ({hrefLang: el.locale, url: getLookieroURL(el.domain)})), []);
  const language = useMemo(() => locale.split('-')[0], [locale]);
  const hrefPath = useMemo(() => `${getLookieroURL(domain)}${path}`, [domain, path]);

  return (
    <SEO htmlAttributes={{lang: language}}>
      <meta charSet="utf-8" />
      <title>{titlePage}</title>
      <meta name="description" content={formatMessage({id: `head.description.${content}`})} />
      <meta name="organization" content={formatMessage({id: 'head.organization'})} />
      <meta property="og:title" content={titlePage} />
      <meta property="og:description" content={formatMessage({id: 'head.og.description'})} />
      <meta property="og:image" content={PATH_TO_SHARE_IMAGE} />
      <meta property="og:image:secure_url" content={PATH_TO_SHARE_IMAGE} />
      <meta property="og:url" content={formatMessage({id: 'head.og.url'})} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={formatMessage({id: 'head.og.siteName'})} />
      <meta property="og:locale" content={formatMessage({id: 'head.og.locale'})} />
      <meta property="fb:app_id" content={formatMessage({id: 'head.fb.appId'})} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:description" content={formatMessage({id: `head.description.${content}`})} />
      <meta name="twitter:title" content={formatMessage({id: `head.title.${content}`})} />
      <meta name="twitter:creator" content={formatMessage({id: 'head.twitter'})} />
      <meta name="twitter:site" content={formatMessage({id: 'head.twitter'})} />
      <meta name="google-site-verification" content={process.env.REACT_APP_GOOGLE_SITE_ID} />
      <link rel="canonical" href={hrefPath} />
      {metaUrls.map(({hrefLang, url}) => (
        <link key={url} rel="alternate" hrefLang={hrefLang} href={url} />
      ))}
      <link rel="apple-touch-icon" sizes="192x192" href={`${PATH_TO_FAVICONS}/xxxhdpi_192×192.png`} />
      <link rel="icon" type="image/png" sizes="48x48" href={`${PATH_TO_FAVICONS}/mdpi_48×48.png`} />
      <link rel="icon" type="image/png" sizes="72x72" href={`${PATH_TO_FAVICONS}/hdpi_72×72.png`} />
      <link rel="mask-icon" href={`${PATH_TO_FAVICONS}/xxxhdpi_192×192.png`} color="#5BBAD5" />
      <link rel="shortcut icon" href={`${PATH_TO_FAVICONS}/favicon.ico`} />
      <meta name="msapplication-TileColor" content="#DA532C" />
      <body className={isHomeRoute(path) ? 'home-page' : 'internal-page'} />
    </SEO>
  );
};
