const AVAILABLE_CONTENTS: Record<string, string> = {
  // url : key
  home: 'home',
  'terms-and-conds': 'termsAndConds',
  'help-and-contact': 'helpAndContact',
  prime: 'prime',
  sustainability: 'sustainability',
  giftcard: 'giftcard',
  'referrals-landing': 'home',
};

export const determineContent = (path = ''): string => {
  const content = path.toLowerCase().split('/')[1] || 'home';
  return AVAILABLE_CONTENTS[content] || 'notfound';
};
