import {useCallback, useMemo} from 'react';
import {useIntl as useReactIntl} from 'react-intl';
import parser from 'html-react-parser';

type FormatMessageType = ReturnType<typeof useReactIntl>['formatMessage'];
type FormatMessageDescriptor = Parameters<FormatMessageType>[0];
type FormatMessageValues = Parameters<FormatMessageType>[1];
type FormatMessageOptions = Parameters<FormatMessageType>[2] & {parseHtml?: boolean};
type FormatMessageSettings = Partial<{values: FormatMessageValues; options: FormatMessageOptions}>;

type UseIntlReturnType = {
  loadedMessages: boolean;
  formatMessage: <T = string>(descriptor: FormatMessageDescriptor, settings?: FormatMessageSettings) => T;
} & Omit<ReturnType<typeof useReactIntl>, 'formatMessage'>;

export const useIntl = (): UseIntlReturnType => {
  const intl = useReactIntl();

  const formatMessage = useCallback(
    <T>(descriptor: FormatMessageDescriptor, {values, options}: FormatMessageSettings = {}) => {
      const {parseHtml = true, ...intlOpts} = options || {};
      const hasValue = descriptor.id && intl.messages[descriptor.id];
      const formatted = hasValue ? intl.formatMessage(descriptor, values, intlOpts) : '';
      return (parseHtml && formatted && typeof formatted === 'string' ? parser(formatted) : formatted) as T;
    },
    [intl],
  );

  const loadedMessages = useMemo(() => Object.keys(intl.messages).length > 0, [intl.messages]);

  return useMemo(() => ({...intl, formatMessage, loadedMessages}), [intl, formatMessage, loadedMessages]);
};
