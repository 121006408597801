export interface Experiment {
  name?: string;
  associatedVariation?: {
    name?: string;
  };
}
export interface KameleoonAPI {
  API: {
    Experiments: {
      getActive: () => Experiment[];
    };
  };
}

export interface KameleoonService {
  getVariantForName: (name: string) => string;
}
const kameleoonService = (k?: KameleoonAPI): KameleoonService => {
  return {
    getVariantForName(name: string) {
      if (!(typeof k !== 'undefined' && k)) return '';
      const experiment = k.API.Experiments.getActive().find((e: Experiment) => (e.name || '').trim().endsWith(name));
      return experiment && experiment.associatedVariation ? experiment.associatedVariation.name || '' : '';
    },
  };
};

const defaultKameleoon = kameleoonService();
const retrieveKameleoon = (): KameleoonService => kameleoonService(window.Kameleoon);

const isKameleoonAvailable = () => typeof window.Kameleoon !== 'undefined' && window.Kameleoon;

export {defaultKameleoon, isKameleoonAvailable, retrieveKameleoon};
