import {http} from '@/utils/http';
import {LocaleDictionary} from '../types/Locale';
import {sendException} from '@/modules/Monitoring';
import {i18nTranslationsStatic} from '@/modules/I18N/services/i18nTranslationsStatic';

const LOCALISE_PROJECTS = ['user-site-front', 'promocodes', 'giftcards'];
const translationParams = LOCALISE_PROJECTS.map(project => `projectFilter=${project}`).join('&');

export const i18nTranslationsApi = async (locale: string): Promise<LocaleDictionary> => {
  try {
    const [language, country] = locale.split('-');
    return await http.get<LocaleDictionary>(`/api/v2/translations/${language}/${country}?${translationParams}`);
  } catch (e) {
    sendException(e as Error);
    return await i18nTranslationsStatic(locale);
  }
};
