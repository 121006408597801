export enum Country {
  BE = 'BE',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  IT = 'IT',
  LU = 'LU',
  PT = 'PT',
  GB = 'GB',
  AT = 'AT',
  NL = 'NL',
  SE = 'SE',
  CH = 'CH',
}

export type CountryType = keyof typeof Country;
