export const PATH = {
  HOME: '/',
  FAQ_OLD: '/help-and-contact/',
  FAQ_OLD_RECOMMEND_US: '#referrals-how-it-works',
  LEGAL: '/terms-and-conds/',
  LEGAL_COOKIES: '#cookies-policy',
  SUSTAINABILITY: '/sustainability/',
  UNSUBSCRIBE: '/unsubscribe/:token?',
  USER: '/user/my-lk',
  LOGIN: '/user/login',
  INDEX: '/index.com',
  REFERRALS_LANDING: '/referrals-landing',
  QUIZ: '/user/quiz',
  GIFT_CARD: '/giftcard',
  GIFT_CARD_DIGITAL: '/giftcard/email',
  GIFT_CARD_PRINT: '/giftcard/print',
  GIFT_CARD_THANK_YOU: '/giftcard/thankyou',
  BLOG: '/blog',
} as const;
