import {PATH} from '@/routes';
import {Location} from '@lookiero/quiz';

type LocationState = Location['state'];

interface RedirectToQuizFunctionArgs {
  skipFirstQuestion: boolean;
}

interface RedirectToQuizFunction {
  (args: RedirectToQuizFunctionArgs): void;
}

export const redirectToQuiz: RedirectToQuizFunction = ({skipFirstQuestion}) => {
  const state: LocationState = {
    usf: {
      skipFirstQuestion,
    },
  } as LocationState;

  window.history.pushState(
    {
      idx: 0,
      key: 'default',
      usr: state,
    },
    '',
    PATH.QUIZ,
  );

  window.location.href = PATH.QUIZ;
};
