import StyleSheet from 'react-native-extended-stylesheet';
import {IOS_FB_HEADER_OFFSET} from '@/utils/inAppBrowser';
import {Theme} from '@lookiero/aurora';

const {space12} = Theme.get();

export const style = StyleSheet.create({
  body: {
    justifyContent: 'space-between',
    display: 'flex',
    flex: 1,
    paddingHorizontal: '$space6',
    paddingTop: '$space6',
    paddingBottom: '$space12',
  },
  widthTablet: {
    width: '50%',
  },
  widthWeb: {
    width: '25%',
  },
  fbBrowserBody: {
    paddingBottom: space12 + IOS_FB_HEADER_OFFSET,
  },
});
