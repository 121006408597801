import {http} from '@/utils/http';
import {LocaleDictionary} from '../types/Locale';
import {sendException} from '@/modules/Monitoring';

export const i18nTranslationsStatic = async (locale: string): Promise<LocaleDictionary> => {
  try {
    return await http.get<LocaleDictionary>(`/temp/i18n/locales/${locale}.json`);
  } catch (e) {
    sendException(e as Error);
    return Promise.resolve({});
  }
};
