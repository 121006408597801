const inAppBrowser = (): boolean =>
  /FB_IAB/.test(navigator.userAgent) ||
  /FBAN/.test(navigator.userAgent) ||
  /FBAV/.test(navigator.userAgent) ||
  /Pinterest/.test(navigator.userAgent);

const iOSFBAgent = (): boolean => /FBIOS/.test(navigator.userAgent);

const inAppScreen = (): boolean => window.screen.height > 667;

const iOSInAppBrowser = (): boolean => inAppScreen() && iOSFBAgent();

const isAndroidInAppBrowser = (): boolean => inAppBrowser() && inAppScreen() && !iOSFBAgent();

const IOS_FB_HEADER_OFFSET = 70;

const IN_APP_BROWSER_MODAL_PADDING_BOTTOM = 95;

export {iOSInAppBrowser, isAndroidInAppBrowser, IOS_FB_HEADER_OFFSET, IN_APP_BROWSER_MODAL_PADDING_BOTTOM};
