import React, {useCallback} from 'react';
import {Button, SPACE} from '@lookiero/aurora';

import {useIntl} from '@/modules/I18N';
import {redirectToQuiz} from '@/modules/Quiz/redirectToQuiz';

export const CreateProfileButton = () => {
  const {formatMessage} = useIntl();

  const handleOnPress = useCallback(() => redirectToQuiz({skipFirstQuestion: false}), []);

  return (
    <Button onPress={handleOnPress} nativeID="HomeCtaBurgerMenu" marginBottom={SPACE.SPACE_4} small>
      {formatMessage({id: 'navigation.start.cta'})}
    </Button>
  );
};
