import React, {createContext, useContext, useEffect} from 'react';

import {setupUrlPromo} from '@/modules/Promotion';

interface ContextProps {}

export const AppContext = createContext<ContextProps>({});

export const AppProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  useEffect(() => {
    setupUrlPromo();
  }, []);

  return <AppContext.Provider value={{}}>{children}</AppContext.Provider>;
};

export const useApp = () => useContext(AppContext);
