import {PATH} from '@/routes/Router.config';

export interface LinkProps {
  title: string;
  href?: string;
  to?: string;
}

export enum LinkEntry {
  BLOG = 'BLOG',
  GIFTCARD = 'GIFTCARD',
  FAQ = 'FAQ',
  LEGAL = 'LEGAL',
  SUSTAINABILITY = 'SUSTAINABILITY',
}

export const LINKS: Record<LinkEntry, LinkProps> = {
  [LinkEntry.BLOG]: {
    title: 'navigation.menu.blog',
    href: PATH.BLOG,
  },
  [LinkEntry.GIFTCARD]: {
    title: 'navigation.menu.giftCard',
    href: PATH.GIFT_CARD,
  },
  [LinkEntry.FAQ]: {
    title: 'navigation.menu.help',
    href: PATH.FAQ_OLD,
  },
  [LinkEntry.LEGAL]: {
    title: 'navigation.menu.tc',
    to: PATH.LEGAL,
  },
  [LinkEntry.SUSTAINABILITY]: {
    title: 'sustainability.title',
    to: PATH.SUSTAINABILITY,
  },
};
