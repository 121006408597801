import React, {Suspense} from 'react';
import {View} from '@lookiero/aurora';

import {Route, Router, Switch} from './bridge';
import {BaseLayout, BaseLayoutConfig} from '@/components/templates';
import {hasSite} from '@/modules/Site';
import {
  Home,
  HomeSiteCom,
  Legal,
  NotFound,
  Unsubscribe,
  Sustainability,
  Faq,
  GiftCardLanding,
  GiftCardDigital,
  GiftCardPrint,
  ReferralsLanding,
  GiftCardThankYou,
} from './Router.components';
import {RouterEvents} from './components/RouterEvents';
import {PATH} from './Router.config';

const GC_HEADER_CONFIG = {
  visible: true,
  showLogin: false,
  showMenu: false,
};

const GC_LANDING_CONFIG: BaseLayoutConfig = {
  header: GC_HEADER_CONFIG,
};

const GC_FORM_CONFIG: BaseLayoutConfig = {
  header: {
    ...GC_HEADER_CONFIG,
    visible: false,
  },
};

const AppRouter = () => (
  <Router basename="/">
    <RouterEvents />
    <Suspense fallback={<View />}>
      {hasSite() ? (
        <Switch>
          <BaseLayout exact path={PATH.HOME} component={Home} localTranslations />
          <Route path={PATH.FAQ_OLD} component={Faq} />
          <BaseLayout exact path={PATH.LEGAL} component={Legal} />
          <BaseLayout exact path={PATH.SUSTAINABILITY} component={Sustainability} />
          <BaseLayout path={PATH.UNSUBSCRIBE} component={Unsubscribe} />
          <BaseLayout exact path={PATH.GIFT_CARD} component={GiftCardLanding} config={GC_LANDING_CONFIG} />
          <BaseLayout exact path={PATH.GIFT_CARD_DIGITAL} component={GiftCardDigital} config={GC_FORM_CONFIG} />
          <BaseLayout exact path={PATH.GIFT_CARD_PRINT} component={GiftCardPrint} config={GC_FORM_CONFIG} />
          <BaseLayout exact path={PATH.REFERRALS_LANDING} component={ReferralsLanding} />
          <Route exact path={PATH.INDEX} component={HomeSiteCom} />
          <Route exact path={PATH.GIFT_CARD_THANK_YOU} component={GiftCardThankYou} />
          <BaseLayout component={NotFound} />
        </Switch>
      ) : (
        <Switch>
          <Route exact component={HomeSiteCom} />
        </Switch>
      )}
    </Suspense>
  </Router>
);

export {AppRouter};
