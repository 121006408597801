import {Mendel as MendelClient} from '@lookiero/mendel-web-client';

import {isSeoRender} from '@/modules/SEO';
import {UI_EVENT, eventManager} from '@/modules/EventManager';
import {MendelService} from './_types/Mendel';
import {getScope} from './getScope';

const init = () => {
  const mendel = new MendelClient();
  mendel.setApiHost('/mendel');
  mendel.setPrefix('web_');
  return mendel;
};

const mendel = init();

const getVariantByExperimentName = async (name: string, forcedVariant = ''): Promise<string | undefined> => {
  const scope = await getScope();

  const assignedVariant = mendel.getAssignedVariantByExperimentName(name);
  if (assignedVariant) return assignedVariant.variant;

  const experiments = await mendel.getActiveExperiments();
  const experiment = experiments.find(exp => exp.name === name);

  if (experiment) {
    const assignedVariant = await mendel
      .getExperimentForUser(experiment.name, mendel.getUserId(), scope, forcedVariant)
      .catch(() => undefined);

    if (assignedVariant) {
      eventManager.emit(UI_EVENT.EXPERIMENT_APPLIED, {
        eventAction: assignedVariant.variant,
        eventLabel: name,
      });

      return assignedVariant.variant;
    }
  }
  return;
};

const mendelService: MendelService = {
  getVariantByExperimentName: async ({name, forcedVariant}) => {
    if (isSeoRender) return undefined;
    return getVariantByExperimentName(name, forcedVariant);
  },
  getScope: getScope,
};

export {mendelService};
