import React from 'react';
import {ALIGN, Header as AuroraHeader, Panel, useDevice, View} from '@lookiero/aurora';

import {useScrollLock} from '@/hooks/useScrollLock';
import {CreateProfileButton, LoginButton} from './components';
import {Menu} from './components/Menu';
import {IDS} from './PanelContent.definition';
import {style} from './PanelContent.styles';
import {iOSInAppBrowser} from '@/utils/inAppBrowser';

interface PanelContentProps {
  isVisible: boolean;
  onClose: () => void;
}

export const PanelContent: React.FC<PanelContentProps> = ({isVisible, onClose}) => {
  const {screen} = useDevice();
  useScrollLock(isVisible);

  const screenSize = {
    tablet: screen.width >= 768,
    web: screen.width >= 1280,
  };

  return (
    <Panel
      nativeID={IDS.PANEL}
      hasOverlay
      isVisible={isVisible}
      align={ALIGN.RIGHT}
      onClose={onClose}
      style={[screenSize.tablet && style.widthTablet, screenSize.web && style.widthWeb]}
    >
      <AuroraHeader onClose={onClose} />
      <View style={[style.body, iOSInAppBrowser() && style.fbBrowserBody]}>
        <Menu />
        <View>
          <CreateProfileButton />
          <LoginButton />
        </View>
      </View>
    </Panel>
  );
};
