import React, {useState, useEffect} from 'react';
import StyleSheet from 'react-native-extended-stylesheet';
import {Image, View} from '@lookiero/aurora';

import {HEADER_HEIGHT} from '@/components/organisms/Header';
import {isSeoRender} from '@/modules/SEO';

const TIME_TO_HIDE = 2000;

const styles = StyleSheet.create({
  logo: {
    position: 'absolute',
    top: 0,
    height: HEADER_HEIGHT,
    justifyContent: 'center',
    paddingLeft: 16,
  },
  image: {
    width: 102,
    height: 20,
  },
});

export const LoadingLogo: React.FC<{isVisible?: boolean}> = ({isVisible = true}) => {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const idTimeout = isVisible ? window.setTimeout(() => setShowLoading(false), TIME_TO_HIDE) : 0;

    return () => window.clearTimeout(idTimeout);
  }, [isVisible]);

  return (
    (showLoading || isSeoRender) && (
      <View style={styles.logo} nativeID="loading-logo">
        <Image srcSet="/assets/Logo_White_x2.gif" style={styles.image} />
      </View>
    )
  );
};
