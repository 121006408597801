import React from 'react';
import {Link, Text, View} from '@lookiero/aurora';

import {NavigationLink} from '@/components/atoms';
import {useIntl} from '@/modules/I18N';
import {usePanelLinks} from '../../hooks/usePanelLinks';
import {LinkProps} from '../../config/Links';
import {styles} from './Menu.styles';

const MenuItem: React.FC<{item: LinkProps}> = ({item}) => {
  const {formatMessage} = useIntl();

  if (item.to) {
    return (
      <NavigationLink to={item.to} exact>
        <Text action level={1}>
          {formatMessage({id: item.title})}
        </Text>
      </NavigationLink>
    );
  }

  return (
    <Link action href={item.href} level={1} target="_blank" style={styles.linkText}>
      {formatMessage({id: item.title})}
    </Link>
  );
};

export const Menu: React.FC = () => {
  const links = usePanelLinks();

  return (
    <View as="ul" style={styles.container}>
      {links.map(link => (
        <View as="li" key={link.title} style={styles.link}>
          <MenuItem item={link} />
        </View>
      ))}
    </View>
  );
};
