import React from 'react';

import {NavLink} from '@/routes';
import styles from './NavigationLink.module.css';

interface NavigationLinkProps {
  to: string;
  children: React.ReactNode;
  exact?: boolean;
}

export const NavigationLink: React.FC<NavigationLinkProps> = ({to, children, exact = false}) => {
  return (
    <NavLink className={styles.navigationLink} to={to} exact={exact}>
      {children}
    </NavLink>
  );
};
