import {getSiteConfig} from '@/modules/Site';
import {DEVICE} from './_types';

const MATCH_MOBILE = /^Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/i;

const getScope = async (): Promise<string> => {
  const {site} = getSiteConfig();
  const authorization = false;

  const userAgent = navigator.userAgent.toLowerCase();
  const mobile = Boolean(userAgent.match(MATCH_MOBILE));

  return `store=${site}&registered=${authorization}&device=${mobile ? DEVICE.MOBILE : DEVICE.DESKTOP}`;
};

export {getScope};
